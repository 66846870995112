<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>協会費請求：{{ type == "nichirei" ? "日冷倉協" : "冷事協会" }}</h2>
      </v-col>
    </v-row>

    <v-row class="justy">
      <v-col cols="3">
        <v-select label="協会名"
          v-model="association_id"
          :items="associationList"
          item-text="associationName"
          item-value="associationId"
          dense
          :loading="loading"
          :disabled="loading"
          @change="loadList()"
          class="mb-5"
        ></v-select>

      </v-col>
      <v-col class="flex growClear">
        <v-btn
              color="primary"
              width="160"
              :disabled="loading || mode == 'edit'"
              @click="totalExcelDownload()"
        >
          <v-icon :small="true">mdi-file</v-icon>
          合計表ﾀﾞｳﾝﾛｰﾄﾞ
        </v-btn>
        <v-btn
              color="primary"
              width="180"
              :disabled="loading || mode == 'edit'"
              @click="showFeeText()"
        >
          <v-icon :small="true">mdi-file</v-icon>
          請求書本文確認
        </v-btn>
        <v-btn
              :class="mode == 'edit' ? 'secondary' : ''"
              color="primary"
              width="180"
              :disabled="loading"
              @click="toggleMode()"
        >
          <v-icon :small="true">mdi-border-color</v-icon>
          {{ mode == "fix" ? "単価、減額 編集" : "編集確定" }}
        </v-btn>
        <v-btn
              color="primary"
              width="180"
              :disabled="loading || mode == 'edit' || selectAssociation.length <= 0"
              @click="makePdf()"
        >
          <v-icon :small="true">mdi-file</v-icon>
          請求書PDF作成
        </v-btn>
        <v-btn
              color="primary"
              width="180"
              :disabled="loading || !isEnableDownload"
              @click="goto_mail()"
        >
          <v-icon :small="true">mdi-send</v-icon>メール送信画面へ
        </v-btn>
      </v-col>
    </v-row>

    <!-- 日冷倉協 -->
    <div v-if="type == 'nichirei'" class="tableWrap">
      <v-data-table
            :items="nichireiMemberData"
            :disable-pagination='true'
            hide-default-header
            hide-default-footer
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th rowspan="4" width="50">
                <v-checkbox
                  v-model="allCheck"
                  @change="all_check()"
                ></v-checkbox>
              </th>
              <th rowspan="4" width="200">協会名</th>
              <th rowspan="2" width="120">定額会費<br>A</th>
              <th colspan="10" width="1200">設備割会費</th>
              <th colspan="2" width="240">容積(㎥)免除額</th>
              <th rowspan="4" width="120">合計<br>F=C-D-E</th>
              <th rowspan="4" width="120">減額<br>G</th>
              <th rowspan="4" width="120">合計<br>H=F-G</th>
              <th rowspan="4" width="120">調整費<br>I</th>
              <th rowspan="4" width="120">合計<br>J=H-I</th>
              <th rowspan="4" width="320">請求書PDF</th>
              <!-- <th rowspan="4" width="320">宛先</th> -->
            </tr>
            <tr>
              <th colspan="4">F級</th>
              <th colspan="4">C級</th>
              <th width="120">計</th>
              <th rowspan="3" width="120">小計<br>(C=A+B)</th>
              <th rowspan="2" width="120">移動ラック倉庫減額<br>D</th>
              <th rowspan="2" width="120">立体自動倉庫減額<br>E</th>
            </tr>
            <tr>
              <th width="120">工場数</th>
              <th width="120">～2500㎥未満<br>容積(㎥)</th>
              <th width="120">2500～5000㎥<br>容積(㎥)</th>
              <th width="120">5000㎥以上<br>容積(㎥)</th>
              <th rowspan="2" width="120">計</th>
              <th width="120">～2500㎥未満<br>容積(㎥)</th>
              <th width="120">2500～5000㎥<br>容積(㎥)</th>
              <th width="120">5000㎥以上<br>容積(㎥)</th>
              <th rowspan="2" width="120">計</th>
              <th rowspan="2" width="120">B</th>
            </tr>

            <tr>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.office" prefix="@" suffix="円" :readonly="mode == 'fix'" hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.fVolumeS" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.fVolumeM" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.fVolumeL" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.cVolumeS" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.cVolumeM" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.cVolumeL" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.volumeExemptionOfMove" suffix="%" :readonly="mode == 'fix'" dense hide-details />
                </th>
              <th :class="editColor">
                <NumberTextField v-model="nichireiUnitPrice.volumeExemptionOfSolid" suffix="%" :readonly="mode == 'fix'" dense hide-details />
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{items}" >
          <tbody v-for="(item, index) in items" :key="item.associationId">
            <tr>
              <td rowspan="2" width="50">
                <v-checkbox
                      v-model="selectAssociation"
                      :value="item.email ? item.associationId : false"
                      :disabled="!item.email"
                ></v-checkbox>
              </td>
              <td rowspan="2" width="200">
                {{ item.associationName }}
                <div v-if="!item.email" class="caution">
                  送信メールアドレスが設定されていないため選択できません
                </div>
              </td>
              <td class="money-string">
                {{dispNumber(item.office)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeS)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeM)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeL)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeTotal)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeS)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeM)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeL)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeTotal)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.totalB)}}
              </td>
              <td></td>

              <td class="money-string">
                {{dispNumber(item.volumeExemptionOfMove)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.volumeExemptionOfSolid)}}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td rowspan="2" width="350">
                <v-btn v-if="item.associationName != null"
                  color="primary"
                  width="280"
                  :disabled="!(isEnableDownload && download_path[index].enable)"
                  @click="filePreview(download_path, index)"
                >
                  <v-icon :small="true">mdi-file</v-icon>
                  {{ item.associationName + ".pdf" }}
                </v-btn>
                <br>
                <v-btn class="mt-1" v-if="item.associationName != null"
                  color="primary"
                  width="280"
                  :disabled="!(isEnableDownload && download_path[index].enable)"
                  @click="filePreview2(download_path, index)"
                >
                  <v-icon :small="true">mdi-file</v-icon>
                  {{ item.associationName + ".xlsx" }}
                </v-btn>
              </td>
              <!-- <td rowspan="2" class="preline">
                {{ replaceCommaToBR(item.email) }}
              </td> -->
            </tr>

            <tr>
              <td class="money-string">
                {{dispNumber(item.officeCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeSCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeMCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeLCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.fVolumeTotalCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeSCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeMCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeLCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.cVolumeTotalCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.totalBCost)}}
                </td>
              <td class="money-string">
                {{dispNumber(item.totalC)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.volumeExemptionOfMoveCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.volumeExemptionOfSolidCost)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.totalF)}}
              </td>
              <td :class="editColor + ' money-string'">
                <NumberTextField v-model="item.exemption" :readonly="mode == 'fix' ? true : false" dense hide-details />
              </td>
              <td class="money-string">
                {{dispNumber(item.totalH)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.adjustment)}}
              </td>
              <td class="money-string">
                {{dispNumber(item.totalJ)}}
              </td>
            </tr>
          </tbody>

        </template>
      </v-data-table>
    </div>

    <!-- 冷事協会 -->
    <div v-else-if="type == 'reiji'" class="tableWrap">
      <v-data-table
            :items="reijiMemberData"
            :disable-pagination='true'
            hide-default-header
            hide-default-footer
      >

        <template v-slot:header>
          <thead>
            <tr>
              <th rowspan="3" width="50">
                <v-checkbox
                  v-model="allCheck"
                  @change="all_check()"
                ></v-checkbox>
              </th>
              <th rowspan="3" width="200">協会名</th>
              <th rowspan="1" width="120">定額会費<br>A</th>
              <th colspan="6" width="720">設備割会費</th>
              <th rowspan="3" width="120">合計<br>A+B</th>
              <th rowspan="3" width="120">調整費<br>C</th>
              <th rowspan="3" width="120">試算額<br>A+B-C</th>
              <th rowspan="3" width="120">請求額<br>D</th>
              <th rowspan="3" width="120">減額<br>E</th>
              <th rowspan="3" width="120">減額後請求額<br>D-E</th>
              <th rowspan="2" width="120">特別会費<br>F</th>
              <th rowspan="3" width="120">会費請求額<br>D-E+F</th>
              <th rowspan="3" width="320">請求書PDF</th>
              <!-- <th rowspan="3" width="320">宛先</th> -->
            </tr>
            <tr>
              <th width="120">工場数</th>
              <th width="120">F級</th>
              <th width="120">C級</th>
              <th width="120">製氷(陸上氷)<br>日産[t]</th>
              <th width="120">製氷(水産氷)<br>日産[t]</th>
              <th width="120">凍結<br>日産[t]</th>
              <th rowspan="2" width="120">計<br>B</th>
            </tr>
            <tr>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.office" prefix="@" suffix="円" :readonly="mode == 'fix'" hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.fVolume" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.cVolume" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.lIceCapability" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.wIceCapability" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.frozenCapability" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
              <th :class="editColor">
                <NumberTextField v-model="reijiUnitPrice.specialF" prefix="@" suffix="円" :readonly="mode == 'fix'" dense hide-details />
              </th>
            </tr>
          </thead>
        </template>

        <!-- <template v-slot:item="{ item, index }"> -->
        <template v-slot:body="{items}" >
          <tbody v-for="(item, index) in items" :key="item.associationId">
            <tr>
              <td rowspan="2" width="50">
                <v-checkbox v-model="selectAssociation"
                      :value="item.email ? item.associationId : false"
                      :disabled="!item.email"
                ></v-checkbox>
              </td>
              <td rowspan="2" width="200">
                {{ item.associationName }}
                <div v-if="!item.email" class="caution">
                  送信メールアドレスが設定されていないため選択できません
                </div>
              </td>
              <td class="money-string">
                {{ dispNumber(item.office) }}
              </td>
              <td class="money-string">
                <!-- {{ dispNumber(item.fVolumeTotal) }} -->
                {{ dispNumber(item.fVolumeTotalFrozen) }}
              </td>
              <td class="money-string">
                <!-- {{ dispNumber(item.cVolumeTotal) }} -->
                {{ dispNumber(item.cVolumeTotalFrozen) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.lIceCapability) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.wIceCapability) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.frozenCapability) }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td rowspan="2" width="350">
                <v-btn color="primary"
                      width="280"
                      :disabled="!(isEnableDownload && download_path[index].enable)"
                      @click="filePreview(download_path, index)"
                >
                  <v-icon :small="true">mdi-file</v-icon>
                  {{ item.associationName + ".pdf" }}
                </v-btn>
                <br>
                <v-btn class="mt-1" color="primary"
                      width="280"
                      :disabled="!(isEnableDownload && download_path[index].enable)"
                      @click="filePreview2(download_path, index)"
                >
                  <v-icon :small="true">mdi-file</v-icon>
                  {{ item.associationName + ".xlsx" }}
                </v-btn>
              </td>
              <!-- <td rowspan="2" class="preline">
                {{ replaceCommaToBR(item.email) }}
              </td> -->
            </tr>
            <tr>
              <td class="money-string">
                {{ dispNumber(item.officeCost) }}
                </td>
              <td class="money-string">
                {{ dispNumber(item.fVolumeTotalCost) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.cVolumeTotalCost) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.lIceCapabilityCost) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.wIceCapabilityCost) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.frozenCapabilityCost) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.totalB) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.totalAB) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.adjustment) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.totalABC) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.amountD) }}
              </td>
              <td :class="editColor">
                <NumberTextField v-model="item.exemption" :readonly="mode == 'fix'" dense hide-details />
              </td>
              <td class="money-string">
                {{ dispNumber(item.totalDE) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.specialF) }}
              </td>
              <td class="money-string">
                {{ dispNumber(item.amountDEF) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5">{{actionMessage}}</h3>
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

    <!------------------------------------------------------------------------>
    <!-- 請求書本文編集 modal -->
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <modal name="modal-text-dialog" :draggable="false" :clickToClose="true" :scrollable="true" height="auto" width="60%" id="vm--modal">
          <div v-if="feeData.length > 0" class="modal-body pt-10 pb-10">
            <h2>請求書本文</h2>
            <table class="text-table mt-3">
              <tr>
                <th>発信No</th>
                <td>
                  <NumberTextField v-model.number="feeData[0]['text']"
                      :rules="[required]" dense
                  ></NumberTextField>
                </td>
                <th>日付</th>
                <td>
                  <DatePicker
                      v-model="feeData[13]['text']"
                      format="YYYY/MM/DD"
                      selectType="date"
                      :rules="[required]"
                      :clearable="false"
                  ></DatePicker>
                </td>
              </tr>
              <tr>
                <th>本文1</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[1]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文2</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[2]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文3</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[3]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文4</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[4]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文5</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[5]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文6</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[6]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>本文7</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[7]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <!-- <tr v-if="type == 'nichirei'"> -->
              <tr>
                <th>本文8</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[8]['text']" dense hide-details></v-text-field>
                </td>
              </tr>

              <tr>
                <th>取引銀行1</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[9]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>取引銀行2</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[10]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>口座名ｶﾅ</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[11]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
              <tr>
                <th>口座名</th>
                <td colspan="3">
                  <v-text-field v-model="feeData[12]['text']" dense hide-details></v-text-field>
                </td>
              </tr>
            </table>

            <v-row class="mt-5">
              <v-col cols="6" class="text-center">
                <v-btn v-if="mode!='delete'" class="btn_font primary" width="120" v-on:click="registFeeText()">データを保存</v-btn>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-btn class="btn_font primary" width="120" v-on:click="hideFeeText()">戻る</v-btn>
              </v-col>
            </v-row>

          </div>
        </modal>
      </v-form>

  </v-container>
</template>


<script>
import RequestAssociationFee from "../model/requestAssociationFee";
import Association from "../model/m_association";
import NumberTextField from "./common/Number_text_field.vue";
import DatePicker from "./common/DatePicker.vue";
import Download from "../model/download";
import UnitPrice from "../model/unitPrice";
import saveAs from "file-saver";
import Spinner from 'vue-simple-spinner'
import BigNumber from "bignumber.js";

import Message from "../Lib/Message";
import Util from "../Lib/Util";
import Debug from "../Lib/Debug";

export default {
  components: {
    NumberTextField,
    DatePicker,
    Spinner
  },

  data: () => ({
    valid: true,
    loading: false,

    type: "",
    association_id: "0",
    associationList: [],

    isEnableDownload: false,
    download_path: [],

    selectAssociation: [],
    allCheck: false,

    actionMessage: "",

    // 単価
    nichireiUnitPrice: {
      office: 0,
      fVolumeS: 0,
      fVolumeM: 0,
      fVolumeL: 0,
      cVolumeS: 0,
      cVolumeM: 0,
      cVolumeL: 0,
      volumeExemptionOfMove: 0,
      volumeExemptionOfSolid: 0,
    },
    // 単価
    reijiUnitPrice: {
      office: 0,
      fVolume: 0,
      cVolume: 0,
      lIceCapability: 0,
      wIceCapability: 0,
      frozenCapability: 0,
      specialF: 0,
    },

    mode: "fix", // 単価編集 可(edit) or 不可(fix) ※ページ遷移時は不可(fix)

    // 検索条件
    // searchCondition: [], // 検索条件
    searchCondition: {
      type: 0, // type:0が冷蔵
    },

    nichireiMemberData: [], // 日冷倉協データ
    reijiMemberData: [], // 冷事協会データ
    feeData: [],

    selectMemberData: [], // 検索結果データ
  }),

  // ライフサイクルフック
  created: function() {
  },

  mounted: function() {
   // Typeコードを取得
    this.type = this.$route.params['type'];
    Debug.log2("AssosiationFeeType->", this.type);

    // 全てを選択
    this.association_id = "0";

    this.getAssociationList();
    this.loadList();
  },

  methods: {
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // DBから協会情報取得
      try {
        const res = await RequestAssociationFee.getData(this.type);
        if (res && res.data) {
          if (this.type == "nichirei") {
            await UnitPrice.getUnitPriceData("nichirei")
                .then(res => {
                  if (res.data) {
                    this.nichireiUnitPrice = res.data;

                    Debug.log2("nichireiUnitPrice:", this.nichireiUnitPrice);
                  }
                });

            this.nichireiMemberData = res.data;
            if (this.association_id != "0") {
              this.nichireiMemberData = this.nichireiMemberData.filter(md => md.associationId == this.association_id);
            }
            // 単価を入れて計算
            this.calcNichireiCost();

            Debug.log(this.nichireiMemberData);
          } else {
            await UnitPrice.getUnitPriceData("reiji")
                .then(res => {
                  if (res.data) {
                    this.reijiUnitPrice = res.data;

                    Debug.log2("reijiUnitPrice:", this.reijiUnitPrice);

                  }
                });

            this.reijiMemberData = res.data;
            if (this.association_id != "0") {
              this.reijiMemberData = this.reijiMemberData.filter(md => md.associationId == this.association_id);
            }
            // 単価を入れて計算
            this.calcReijiCost();

            Debug.log(this.reijiMemberData);
          }
        }

      } catch (error) {
        Message.err(error, "会員情報を取得できませんでした");
      } finally {
        // デフォルトは全チェック
        this.allCheck = true;
        this.all_check();

        this.isEnableDownload = false;
        this.loading = false;
      }

    },

    async getAssociationList() {
      Debug.log("function[getAssociationList]");

      // DBから協会情報取得
      this.searchCondition.type = (this.type == "nichirei" ? 0: 1);
      Association.search(this.searchCondition)
          .then(res => {
            if (res && res.data) {
              this.associationList = [{ associationName: "すべて", associationId: "0" }];
              this.associationList = this.associationList.concat(res.data);   // 「すべて」がある既存associationListに冷蔵倉庫協会一覧を追加

              // 日冷倉を除く
              this.associationList = this.associationList.filter(a => a.associationId != "000000");

              Debug.log2("associationList->", this.associationList);
            }
            this.loading = false;
          })
          .catch((err) => {
            Message.err(err, "協会情報を取得できませんでした");
          });

      //
      Debug.log2("getAssociationFeeData->", this.type);
      Association.getAssociationFeeData(this.type)
          .then(res => {
            this.feeData = res.data;

            this.feeData.push({"number": 13, "text": Util.getToday()});
            this.$set(this.feeData[0], 'text', Number(this.feeData[0]['text']));
            Debug.log2("FeeData", this.feeData);
          })
          .catch((err) => {
            Message.err(err, "請求書情報を取得できませんでした");
          });

    },

    registFeeText() {
      Debug.log("function[registFeeText]");
      // Vuetify Validation
      if (!this.$refs.form.validate()) {
        return;
      }

      Association.setAssociationFeeData(this.type, this.feeData)
          .then(() => {
            Debug.log("AssociationFeeData Regist");
            alert("請求書本文を保存しました。");

            this.hideFeeText();
          })
          .catch((err) => {
            Message.err(err, "請求書情報を保存できませんでした");
          });

    },


    // 会費計算（日冷倉）
    calcNichireiCost() {
      this.nichireiMemberData.forEach((val, index) => {
        this.nichireiMemberData[index].officeCost = val.office * this.nichireiUnitPrice.office; // 定額会費 A

        //this.nichireiMemberData[index].fVolumeSCost = val.fVolumeS * this.nichireiUnitPrice.fVolumeS; // F級2500㎥未満
        this.nichireiMemberData[index].fVolumeSCost = this.sumUnitPriceR(val.fVolumeSList, this.nichireiUnitPrice.fVolumeS);
        // this.nichireiMemberData[index].fVolumeMCost = val.fVolumeM * this.nichireiUnitPrice.fVolumeM; // F級2500～5000㎥
        this.nichireiMemberData[index].fVolumeMCost = this.sumUnitPriceR(val.fVolumeMList, this.nichireiUnitPrice.fVolumeM);
        // this.nichireiMemberData[index].fVolumeLCost = val.fVolumeL * this.nichireiUnitPrice.fVolumeL; // F級5000㎥以上
        this.nichireiMemberData[index].fVolumeLCost = this.sumUnitPriceR(val.fVolumeLList, this.nichireiUnitPrice.fVolumeL);
        this.nichireiMemberData[index].fVolumeTotalCost = this.nichireiMemberData[index].fVolumeSCost + this.nichireiMemberData[index].fVolumeMCost + this.nichireiMemberData[index].fVolumeLCost; // F級計

        // this.nichireiMemberData[index].cVolumeSCost = val.cVolumeS * this.nichireiUnitPrice.cVolumeS; // C級2500㎥未満
        this.nichireiMemberData[index].cVolumeSCost = this.sumUnitPriceR(val.cVolumeSList, this.nichireiUnitPrice.cVolumeS);
        // this.nichireiMemberData[index].cVolumeMCost = val.cVolumeM * this.nichireiUnitPrice.cVolumeM; // C級2500～5000㎥
        this.nichireiMemberData[index].cVolumeMCost = this.sumUnitPriceR(val.cVolumeMList, this.nichireiUnitPrice.cVolumeM);
        // this.nichireiMemberData[index].cVolumeLCost = val.cVolumeL * this.nichireiUnitPrice.cVolumeL; // C級5000㎥以上
        this.nichireiMemberData[index].cVolumeLCost = this.sumUnitPriceR(val.cVolumeLList, this.nichireiUnitPrice.cVolumeL);
        this.nichireiMemberData[index].cVolumeTotalCost = this.nichireiMemberData[index].cVolumeSCost + this.nichireiMemberData[index].cVolumeMCost + this.nichireiMemberData[index].cVolumeLCost; // C級計

        this.nichireiMemberData[index].totalBCost = this.nichireiMemberData[index].fVolumeTotalCost + this.nichireiMemberData[index].cVolumeTotalCost; // 計 B

        this.nichireiMemberData[index].totalC = this.nichireiMemberData[index].officeCost + this.nichireiMemberData[index].totalBCost; // 小計 C=A+B


        // this.nichireiMemberData[index].volumeExemptionOfMoveCost = val.volumeExemptionOfMove * (this.nichireiUnitPrice.volumeExemptionOfMove * 0.01); // 移動ラック倉庫減額 D
        this.nichireiMemberData[index].volumeExemptionOfMoveCost
              = ( this.sumUnitPriceR(val.movefSList, this.nichireiUnitPrice.fVolumeS * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                + this.sumUnitPriceR(val.movefMList, this.nichireiUnitPrice.fVolumeM * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                + this.sumUnitPriceR(val.movefLList, this.nichireiUnitPrice.fVolumeL * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                + this.sumUnitPriceR(val.movecSList, this.nichireiUnitPrice.cVolumeS * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                + this.sumUnitPriceR(val.movecMList, this.nichireiUnitPrice.cVolumeM * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                + this.sumUnitPriceR(val.movecLList, this.nichireiUnitPrice.cVolumeL * this.nichireiUnitPrice.volumeExemptionOfMove * 0.01)
                ) // 移動ラック倉庫減額 D

        // this.nichireiMemberData[index].volumeExemptionOfSolidCost = val.volumeExemptionOfSolid * (this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01); // 立体自動倉庫減額 E
        this.nichireiMemberData[index].volumeExemptionOfSolidCost
              = ( this.sumUnitPriceR(val.autofSList, this.nichireiUnitPrice.fVolumeS * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                + this.sumUnitPriceR(val.autofMList, this.nichireiUnitPrice.fVolumeM * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                + this.sumUnitPriceR(val.autofLList, this.nichireiUnitPrice.fVolumeL * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                + this.sumUnitPriceR(val.autocSList, this.nichireiUnitPrice.cVolumeS * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                + this.sumUnitPriceR(val.autocMList, this.nichireiUnitPrice.cVolumeM * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                + this.sumUnitPriceR(val.autocLList, this.nichireiUnitPrice.cVolumeL * this.nichireiUnitPrice.volumeExemptionOfSolid * 0.01)
                ) // 立体自動倉庫減額 E

        this.nichireiMemberData[index].totalF = this.nichireiMemberData[index].totalC - this.nichireiMemberData[index].volumeExemptionOfMoveCost - this.nichireiMemberData[index].volumeExemptionOfSolidCost; // 合計 F=C-D-E



        this.nichireiMemberData[index].totalH = this.nichireiMemberData[index].totalF - this.nichireiMemberData[index].exemption; // 合計 H=F-G

        this.nichireiMemberData[index].totalJ = Math.floor(this.nichireiMemberData[index].totalH / 1000) * 1000; // 合計 J=H-I 百の位以下切捨て

        this.nichireiMemberData[index].adjustment = this.nichireiMemberData[index].totalH - this.nichireiMemberData[index].totalJ; // 調整費

      });

    },

    // 会費計算（冷事）
    calcReijiCost() {
      this.reijiMemberData.forEach((val, index) => {
        this.reijiMemberData[index].officeCost = val.office * this.reijiUnitPrice.office; // 定額会費 A

        // this.reijiMemberData[index].fVolumeTotalCost = val.fVolumeTotal * this.reijiUnitPrice.fVolume; // F級計
        // this.reijiMemberData[index].fVolumeTotalCost = this.sumUnitPrice(val.fVolumeList, this.reijiUnitPrice.fVolume);
        // 集計を変更 => 日冷倉分を合計から除く
        this.reijiMemberData[index].fVolumeTotalCost = val.fVolumeTotalFrozen * this.reijiUnitPrice.fVolume;

        // this.reijiMemberData[index].cVolumeTotalCost = val.cVolumeTotal * this.reijiUnitPrice.cVolume; // C級計
        // this.reijiMemberData[index].cVolumeTotalCost = this.sumUnitPrice(val.cVolumeList, this.reijiUnitPrice.cVolume);
        // 集計を変更 => 日冷倉分を合計から除く
        this.reijiMemberData[index].cVolumeTotalCost = val.cVolumeTotalFrozen * this.reijiUnitPrice.cVolume;

        // this.reijiMemberData[index].lIceCapabilityCost = val.lIceCapability * this.reijiUnitPrice.lIceCapability; // 製氷(陸上氷)
        // this.reijiMemberData[index].wIceCapabilityCost = val.wIceCapability * this.reijiUnitPrice.wIceCapability; // 製氷(水産氷)
        this.reijiMemberData[index].lIceCapabilityCost = this.sumUnitPrice(val.lIceCapabilityList, this.reijiUnitPrice.lIceCapability);
        this.reijiMemberData[index].wIceCapabilityCost = this.sumUnitPrice(val.wIceCapabilityList, this.reijiUnitPrice.wIceCapability);

        // this.reijiMemberData[index].frozenCapabilityCost = val.frozenCapability * this.reijiUnitPrice.frozenCapability; // 凍結
        this.reijiMemberData[index].frozenCapabilityCost = this.sumUnitPrice(val.frozenCapabilityList, this.reijiUnitPrice.frozenCapability);

        this.reijiMemberData[index].totalB = this.reijiMemberData[index].fVolumeTotalCost + this.reijiMemberData[index].cVolumeTotalCost + this.reijiMemberData[index].lIceCapabilityCost + this.reijiMemberData[index].wIceCapabilityCost + this.reijiMemberData[index].frozenCapabilityCost; // 計 B

        this.reijiMemberData[index].totalAB = this.reijiMemberData[index].officeCost + this.reijiMemberData[index].totalB; // 合計 A+B

        this.reijiMemberData[index].totalABC = Math.floor(this.reijiMemberData[index].totalAB / 1000) * 1000; // 合計 J=H-I 百の位以下切捨て

        this.reijiMemberData[index].adjustment = this.reijiMemberData[index].totalAB - this.reijiMemberData[index].totalABC; // 調整費

        // 請求額
        switch (true) {
          case this.reijiMemberData[index].totalABC == 0:
            this.reijiMemberData[index].amountD = 0;
            break;
          case this.reijiMemberData[index].totalABC >= 500000:
            this.reijiMemberData[index].amountD = 50000;
            break;
          case this.reijiMemberData[index].totalABC >= 100000:
            this.reijiMemberData[index].amountD = 30000;
            break;
          case this.reijiMemberData[index].totalABC >= 50000:
            this.reijiMemberData[index].amountD = 20000;
            break;
          case this.reijiMemberData[index].totalABC > 10000:
            this.reijiMemberData[index].amountD = 10000;
            break;
          case this.reijiMemberData[index].totalABC <= 100000:
            this.reijiMemberData[index].amountD = 5000;
            break;
        }

        this.reijiMemberData[index].totalDE = this.reijiMemberData[index].amountD - this.reijiMemberData[index].exemption; // 合計 D-E

        this.reijiMemberData[index].specialF = val.office * this.reijiUnitPrice.specialF; // 特別会費

        this.reijiMemberData[index].amountDEF = this.reijiMemberData[index].amountD - this.reijiMemberData[index].exemption + this.reijiMemberData[index].specialF; // 調整費
      });

    },

    // 単価、減額 編集モードを切替え
    toggleMode() {
      if (this.mode == "fix") {
        this.mode = "edit";
      } else if (this.mode == "edit") {
        if (this.type == "nichirei") {
          this.calcNichireiCost();

          //単価を更新
          UnitPrice.setUnitPriceData("nichirei", this.nichireiUnitPrice);
        } else {
          this.calcReijiCost();

          //単価を更新
          UnitPrice.setUnitPriceData("reiji", this.reijiUnitPrice);
        }
        this.mode = "fix";
        this.isEnableDownload = false;
      }
    },

    /** ファイルプレビュー */
    filePreview(filepath, index) {
      Debug.log("function[filePreview]");
      if (filepath.length >= index) {
        Debug.log("Download:[" + filepath[index].Download_path + "]");
        Download.download(filepath[index].Download_path)
            .then(res => {
              saveAs(res.data, filepath[index].File_name);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
            })
      }
    },

    filePreview2(filepath, index) {
      Debug.log("function[filePreview2]");
      if (filepath.length >= index) {
        Debug.log("Download:[" + filepath[index].Download_path2 + "]");
        Download.download(filepath[index].Download_path2)
            .then(res => {
              saveAs(res.data, filepath[index].File_name2);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
            })
      }
    },

    totalExcelDownload() {
      Debug.log("function[totalExcelDownload]");

      // パラメータを設定
      let type = 0;
      let selectMemberData = this.nichireiMemberData;
      let unitPrice = this.nichireiUnitPrice;
      if (this.type != "nichirei") {
        type = 1;
        selectMemberData = this.reijiMemberData;
        unitPrice = this.reijiUnitPrice;
      }
      if (!confirm("請求書合計表をダウンロードします。")) {
        return;
      }

      RequestAssociationFee.downloadExcel(type, selectMemberData, unitPrice, this.feeData)
          .then((res) => {
            const download_path = res.data.Download_path;
            const filename = res.data.File_name;

            Debug.log("Download:[" + download_path + "]");
            Download.download(download_path)
                .then(res => {
                  Debug.log("FilePath:" + filename );
                  saveAs(res.data, filename);
                })
          })
          .catch((err) => {
            Message.err(err, "合計表をダウンロードできませんでした");
          })
    },

    // 選択した協会の請求書PDFを作成
    async makePdf() {
      Debug.log("function[makePdf]");
      // 単価、減額 編集モードeditの時
      if (this.mode == "edit") {
        alert("単価、減額を確定してください"); // 単価を確定するようアナウンス
        return;
      }

      // パラメータを設定
      let type = 0;
      let selectMemberData = this.nichireiMemberData;
      let unitPrice = this.nichireiUnitPrice;
      if (this.type != "nichirei") {
        type = 1;
        selectMemberData = this.reijiMemberData;
        unitPrice = this.reijiUnitPrice;
      }

      if (!confirm("請求書PDFを作成します。")) {
        return;
      }
      this.actionMessage = "請求書PDFを作成しています。しばらくお待ちください。"
      this.$modal.show('modal-dialog');

      RequestAssociationFee.makePdf(type, selectMemberData, unitPrice, this.selectAssociation, this.feeData)
          .then(res => {
            // this.download_path = res.data;
            this.download_path = [];
            const retrun_data = res.data;
            selectMemberData.forEach(el => {
              const data = retrun_data.find(d => d.association_id == el.associationId);
              if (data != null) {
                this.download_path.push({"enable": true, "association_id": el.associationId,
                                        "Download_path": data.Download_path, "File_name": data.File_name,
                                        "Download_path2": data.Download_path2, "File_name2": data.File_name2
                                        })
              } else {
                this.download_path.push({"enable": false, "association_id": "",
                                        "Download_path": "", "File_name": "", "Download_path2": "", "File_name2": ""})
              }
            });

            Debug.log(this.download_path);
            alert("請求書PDFを作成しました。");
            this.$modal.hide('modal-dialog');

            this.isEnableDownload = true;
          })
          .catch((err) => {
            Message.err(err, "請求書PDFを作成できませんでした");
            this.$modal.hide('modal-dialog');
          })
          .finally(() =>{
          });
    },

    // メール送信画面へ遷移
    goto_mail() {
      Debug.log("function[goto_mail]");

      // 単価、減額 編集モードeditの時
      if (this.mode == "edit") {
        confirm("単価、減額を確定してください"); // 単価を確定するようアナウンス
        return;
      }

      const associationList = this.download_path.filter(el => el.enable);
      Debug.log(associationList);

      this.$router.push({
        path: "/request_association_fee_mail/",
        query: {associationList: JSON.stringify(associationList), type: this.type},
      });
    },

    all_check() {
      this.selectAssociation = [];
      if (this.allCheck) {
        if (this.type == "nichirei") {
          this.nichireiMemberData.forEach(el => {
            if (el.email) {
              this.selectAssociation.push(el.associationId);
            }
          });
        } else {
          this.reijiMemberData.forEach(el => {
            if (el.email) {
              this.selectAssociation.push(el.associationId);
            }
          });
        }
      }
    },

    // 少数以下を四捨五入 + 3桁カンマ
    dispNumber(val) {
      return Math.round(val).toLocaleString();
    },

    replaceCommaToBR(commaString) {
      if (commaString) {
        return commaString.replaceAll(",", "\n\r");
      }
      return "";
    },

    // 合計 （×単価）
    sumUnitPriceR(volume, unitPrice) {
      let sum = 0;
      let split = volume.split(',');

      split.forEach(v => {
        const n = Number(v);
        const bn = (BigNumber(n).times(BigNumber(unitPrice))).toNumber();
        if (n > 0) {
          sum += Math.round(bn);
        }
      });
      return sum;
    },

    // 合計 （×単価）：切り捨て
    sumUnitPrice(volume, unitPrice) {
      let sum = 0;
      const split = volume.split(',');

      split.forEach(v => {
        const n = Number(v);
        const bn = (BigNumber(n).times(BigNumber(unitPrice))).toNumber();

        if (n > 0) {
          sum += Math.floor(bn);
        }
      });
      return sum;
    },


    // ------------------------------------------------------------------------
    // モーダル表示用
    showFeeText () {
      Debug.log('function[showFeeText]');

      this.$modal.show('modal-text-dialog');
    },

    hideFeeText : function () {
      Debug.log('function[hideFeeText]');
      this.$modal.hide('modal-text-dialog');
    },

  },

  computed: {
    editColor() {
      return this.mode == 'edit' ? 'editcolor' : ''
    },
  }

};
</script>

<style scoped lang="scss">

.money-string {
  text-align: right;
  font-size: 16px  !important;
}

.flex {
  display: flex;
}
.justy {
  justify-content: space-between;
}
button + button {
  margin-left: 20px;
}

.editcolor {
  background: #ccffcc;
}
.diseditcolor {
  background: #bbbbbb;
}

.disablecolor {
  background: #CCC !important;
}

.growClear {
  flex-grow: 0;
}
.tableWrap ::v-deep .v-data-table__wrapper {
  height: 70vh;
  overflow-y: auto;
  table {
    // width: 3000px !important;
    thead {
      width: 100%;
      position: sticky !important;
      position: -webkit-sticky !important;
      top: 0;
      z-index: 5;
      background: white;
      th {
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
        height: 30px !important;
      }
      tr:nth-child(1) {
        th {
          border-top: 1px solid #000;
        }
        th:nth-child(1) {
          border-left: 1px solid #000;
          position: sticky !important;
          position: -webkit-sticky !important;
          top: 0;
          left: 0;
          z-index: 5;
          background: white;
        }
        th:nth-child(2) {
          position: sticky !important;
          position: -webkit-sticky !important;
          top: 0;
          left: 50px;
          z-index: 5;
          background: white;
        }
      }
    }
    tbody {
      width: 100%;
      tr:nth-child(odd) {
        td:nth-child(1) {
          border-left: 1px solid #000;
          position: sticky !important;
          position: -webkit-sticky !important;
          left: 0;
          z-index: 4;
          background: white;
        }
        td:nth-child(2) {
          position: sticky !important;
          position: -webkit-sticky !important;
          left: 50px;
          z-index: 4;
          background: white;
        }
      }
      td {
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
      }
      .v-text-field {
        .v-input__control {
          .v-input__slot {
            &:before {
              content: "";
              border-style: none !important;
            }
          }
        }
      }
    }
  }
}

.caution {
  font-size: 8pt;
  color: red;
}

.spinner {
  margin: 50px;
}

.text-table {
  border-collapse:collapse;
  width: 100%;

  th, td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border:solid 1px #888
  }

  th {
    width: 120px;
    background-color: #ddd;
  }

  td {
    padding-left: 20px;
  }
}

</style>
