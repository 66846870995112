<template>
  <div>
    <v-text-field
      :label="label"
      v-bind="$attrs"
      :value="innerValue"
      dense
      :type="type"
      v-on="listeners"
      @focusin="edit"
      @focusout="display"
      @change="save()"
      :class="['right-input', 'no-spin', setInnerTableClass].concat(propClass)"
      :readonly="readonly"
      :prefix="prefix"
      :suffix="suffix"
      :rules="rules"
    >
      <template v-for="(value, name) in $slots" v-slot:[name]>
        <slot :name="name"/>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import Util from '../../Lib/Util'

export default {
  name: "NumberTextField",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    innerTable: {
      type: Boolean,
      default: false
    },
    propClass: [],
    readonly: {
      type: Boolean,
      default: false
    },
    rules: [],
    changeEvt: {
      type: Function,
      default: () => true,
    },
    orgRoundStep: {
      type: Number,
      default: 0
    },
    toLocaleFlg: {
      type: Boolean,
      default: true
    },
    zeroIsNull: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editing: false,
      tmpValue: null,
    };
  },
  computed: {
    listeners() {
      const vm = this;
      return {
        ...vm.$listenres,
        input: event => (vm.innerValue = event)
      };
    },
    innerValue: {
      get() {
        if (this.editing || !this.toLocaleFlg) {
          return this.value != null ? this.value.toString() : "";
        } else {
          return this.value != null ? this.value.toLocaleString('ja-JP', { maximumFractionDigits: 20 }) : "";
        }
      },
      set(newValue) {
        const value = newValue.length > 0 ? Number(newValue) : null;
        this.tmpValue = value;
        //this.$emit("input", value);
      }
    },
    type() {
      if (this.editing) {
        return "number";
      } else {
        return "text";
      }
    },
    setInnerTableClass() {
      if(this.innerTable) {
        return 'inner-table-class';
      } else {
        return '';
      }
    }
  },

  watch: {
      value: {
        immediate: true,
        handler(value) {
          this.tmpValue = value;
          this.changeEvt();
        },
      },
  },

  methods: {
    display() {
      this.editing = false;
    },
    edit() {
      this.editing = true;
    },
    save() {
      // 親コンポーネント（のv-model）に値を渡す
      if(this.readonly) return;

      let val = this.tmpValue;
      if((this.orgRoundStep > 0) && (val != null)) {
        // 四捨五入
        val = Util.orgRound(this.tmpValue, this.orgRoundStep);
        if(this.zeroIsNull && val == 0) {
          val = null;
        }
      }

      // 値の変更を通知するために初期化する
      if(val == null) {
        this.$emit("input", 0);
      } else {
        this.$emit("input", null);
      }
      // 親コンポーネントに値をセット
      this.$nextTick(() => {
        this.$emit("input", val);
      });
    },
  }
};
</script>

<style>
.v-text-field.inner-table-class {
    padding-top: 6px;
}
.v-input.inner-table-class .v-input__slot {
    margin-bottom: 2px;
}
.no-spin input::-webkit-inner-spin-button,
.no-spin input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    -moz-appearance:textfield !important;
}

/* 文字色 */
.v-text-field.input-color-red input {
  color: #ff0000 !important;
}
.v-text-field.input-color-red .v-text-field__prefix {
  color: #ff0000 !important;
}
.v-text-field.input-color-red .v-text-field__suffix {
  color: #ff0000 !important;
}
.v-text-field.input-color-primary input {
  color: var(--v-primary-base) !important;
}
.v-text-field.input-color-primary .v-text-field__prefix {
  color: var(--v-primary-base) !important;
}
.v-text-field.input-color-primary .v-text-field__suffix {
  color: var(--v-primary-base) !important;
}
/* 詳細エリアの文字色 */
.detail-info.v-input .error--text {
    color: var(--v-accent-base) !important;
}
.detail-info.v-input .v-input__slot::before{
    color: var(--v-accent-base) !important;
}
</style>
