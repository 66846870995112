import Axios from "axios";
import token from "./setToken";

export default {
  async getData(org) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/RequestAssociationFee/" + org, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会情報を取得できません[getData]");
      throw error;
    }
  },

  async makePdf(type, FeeDataList, unitPrice, selectList, feeData) {
    let params = new URLSearchParams();
    params.append("type", type);
    params.append("FeeDataList", JSON.stringify(FeeDataList));
    params.append("unitPrice", JSON.stringify(unitPrice));
    params.append("selectList", JSON.stringify(selectList));
    params.append("feeData", JSON.stringify(feeData));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/RequestAssociationFeePdf", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("請求書PDFを作成できません[makePdf]");
      throw error;
    }
  },

  
  async downloadExcel(type, FeeDataList, unitPrice, feeData)
  {
    let params = new URLSearchParams();
    params.append("type", type);
    params.append("FeeDataList", JSON.stringify(FeeDataList));
    params.append("unitPrice", JSON.stringify(unitPrice));
    params.append("feeData", JSON.stringify(feeData));

    try {
      const res = await Axios.post("/api/TotalExcelDownload", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("請求合計表を作成できません[downloadExcel]");
      throw error;
    }
  }


};
