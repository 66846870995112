import Axios from "axios";
// import { types } from "sass";
import token from "./setToken";

export default {

  /**
   * 会員情報リストを取得
   * @returns 
   */  

  async getDataList(type = '') {
    try {
      let type_str = ''
      if (type) {
        type_str = '?type=' + type;
      }

      // await token.checkToken();
      const res = await Axios.get("/api/Massociation" + type_str, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会一覧を取得できません[getDataList]");
      throw error;
    }
  },

  async getData(associationId, type) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/Massociation/" + associationId + "/" + type, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会情報を取得できません[getData]");
      throw error;
    }
  },

  async setData(associationData, mode) {
    let params = new URLSearchParams();
    params.append('associationData', JSON.stringify(associationData));
    params.append('mode', mode);

    try {
      const res = await Axios.post('/api/Massociation', params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会情報を保存できません[setData]");
      throw error;
    }
  },

  async deleteData(sid) {
    try {
      const res = await Axios.delete('/api/Massociation/' + sid, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会情報を削除できません[deleteData]");
      throw error;
    }
  },

  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      const res = await Axios.post("/api/MassociationList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("協会一覧を取得できません[search]");
      throw error;
    }
  },

  /**
   * 請求書本文データを取得
   * @param {*} type 
   * @returns 
   */
  async getAssociationFeeData(type) {
    try {
      const res = await Axios.get("/api/MassociationFeeData/" + type, token.setTokenToHeader());

      return res;
    } catch (error) {
      console.error("請求書データを取得できません[getAssociationFeeData]");
      throw error;
    }
  },

  async setAssociationFeeData(type, feeData) {

    let params = new URLSearchParams();
    params.append('feeData', JSON.stringify(feeData));
    params.append('type', type);

    try {
      const res = await Axios.post('/api/MassociationFeeData', params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("請求書データを保存できません[setAssociationFeeData]");
      throw error;
    }
  
  },

  /**
   * 地区協会関連 対象協会ID取得
   * @param {*} associationId 
   * @returns 
   */
  async getAssociationList(type) {
    try {
      const res = await Axios.get("/api/getAssociationList/" + type, token.setTokenToHeader());

      return res;
    } catch (error) {
      console.error("地区協会関連 対象協会IDを取得できません[getAssociationList]");
      throw error;
    }
  },
  
  /**
   * 地区協会関連 対象協会ID取得
   * @param {*} associationId 
   * @returns 
   */
  async getDistAssocId(associationId) {
    try {
      const res = await Axios.get("/api/getDistAssocId/" + associationId, token.setTokenToHeader());

      return res;
    } catch (error) {
      console.error("地区協会関連 対象協会IDを取得できません[getDistAssocId]");
      throw error;
    }
  },
  


};
