import Axios from "axios";
import token from "./setToken";

export default {

  /**
   * 事業所会員データリストを取得
   * @param {*} key 
   * @returns   Value
   */
  async getUnitPriceData(key) {
     try {
      // await token.checkToken();
      const res = await Axios.get("/api/MunitPirce/" + key, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("請求書単価を取得できません[getUnitData]");
      throw error;
    }
  },

  /**
   * データ更新
   */
  async setUnitPriceData(key, value) {
    const params = new URLSearchParams();
    params.append('key', key);
    params.append('value', JSON.stringify(value));

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/MunitPirce', params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("請求書単価を更新できません[setUnitPriceData]");
      throw error;
    }
  },

};
